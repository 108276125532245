import React from "react";
import Box from "@mui/material/Box";
import "./ContactUs.css";
import Contact from "./contact.jpg";
import { UserOutlined, MailOutlined, PhoneOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

function ContactUs() {
  const [form] = Form.useForm();

  function post(path, params, method = "post") {
    // The rest of this code assumes you are not using a library.
    // It can be made less verbose if you use one.
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  }

  const onFinish = async (values) => {
    console.log("Received values of form: ", values);

    post("https://usebasin.com/f/3b5485bdb550", {
      comment: values.comment,
      email: values.email,
      phone: values.phone,
      username: values.username,
    });
  };

  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "left" }}>
        <Box
          sx={{
            width: "52rem",
            justifyContent: "center",
          }}
        >
          <img width="100%" height="100%" alt="a statue" src={Contact} />
        </Box>

        <Box
          sx={{
            width: "32rem",
            paddingBottom: 2,
            paddingTop: 3,
            justifyContent: "center",
            paddingLeft: 5,
            paddingRight: 5,
          }}
        >
          <section className="consult">CONSULTATION IS FREE</section>

          <h2 className="title">Schedule Consultation</h2>

          <Form
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <Form.Item
              style={{ marginBottom: "20px" }}
              name="username"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input prefix={<UserOutlined />} placeholder="Name" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input prefix={<MailOutlined />} placeholder="Email ID" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "20px" }}
              name="phone"
              rules={[
                {
                  required: true,
                  message: "Please input your phone number!",
                },
              ]}
            >
              <Input prefix={<PhoneOutlined />} placeholder="Phone Number" />
            </Form.Item>

            <Form.Item
              style={{ marginBottom: "30px" }}
              name="comment"
              rules={[
                {
                  required: true,
                  message: "Please input a message",
                },
              ]}
            >
              <Input.TextArea showCount maxLength={500} placeholder="Comment" />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                style={{
                  background: "#2b6777",
                  color: "whitesmoke",
                  fontWeight: 600,
                  marginBottom: "40px",
                }}
              >
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Box>
      </Box>
    </>
  );
}

export default ContactUs;
