import React from 'react';
import '../../App.css';
import './HomeImageSection.css';
// import Paper from './Paper'


function HomeImageSection() {
	return (
		<div className='home-image-container'>
			<div className='home-paper'>Why Andy & Harri PLLC</div>
			{/* <Paper title="Why Andy & Harri PLLC"/> */}
		</div>
	);
}

export default HomeImageSection;
